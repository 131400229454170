import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ForPopupsService } from "../service/for-popups.service";

@Component({
  selector: "app-pop-up-msg",
  templateUrl: "./pop-up-msg.component.html",
  styleUrls: ["./pop-up-msg.component.css"],
})
export class PopUpMsgComponent implements OnInit {
  constructor(
    public activeModal: NgbActiveModal,
    public pop_up: ForPopupsService
  ) {}
  ngOnInit() {
    setTimeout(() => {
      this.activeModal.dismiss();
    }, 3000);
  }
}
