import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

// Bootstrap DataTable
import { DataTablesModule } from "angular-datatables";
import { ToastrModule } from "ngx-toastr";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "./module/material-model";
import { HttpClientModule } from "@angular/common/http";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { DateDirective } from "./date.directive";
import { PopUpMsgComponent } from "./pop-up-msg/pop-up-msg.component";
import { DatePipe } from "@angular/common";
@NgModule({
  declarations: [AppComponent, PopUpMsgComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    DataTablesModule,
    ToastrModule.forRoot({
      timeOut: 1500,
      positionClass: "toast-bottom-right",
      preventDuplicates: true,
    }),
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    HttpClientModule,
    AngularEditorModule,
    NgxUiLoaderModule,
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: "en-GB" }, DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
