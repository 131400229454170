import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ForPopupsService {
  constructor() {}
  pop_up_msg: any = "";
  pop_up_msg_color: any = "";
  pop_up_confirm: boolean = false;
  pop_up_confirm_ques: any = "";
}
